import { ReactNode, useEffect, useState } from 'react';
import {
  createInstance,
  OptimizelyProvider,
  useDecision,
  OptimizelyDecision,
  setLogger,
  setLogLevel,
} from '@optimizely/react-sdk';
import {
  UserAttributes,
  OptimizelyDecideOption,
  LOG_LEVEL,
} from '@optimizely/optimizely-sdk';
import { getUserUuid } from '../../../v2/utils/localStorage';
import { useRouter } from 'next/router';
import { logger } from '../../../utils';
import {
  NEXT__OPTIMIZELY_SDK_KEY,
  OPTIMIZELY_LOG_LEVEL,
} from '../../../config/public';

type HookOptions = {
  autoUpdate?: boolean;
  timeout?: number;
};
type DecideHooksOptions = HookOptions & {
  decideOptions?: OptimizelyDecideOption[];
};
type HookOverrides = {
  overrideUserId?: string;
  overrideAttributes?: UserAttributes;
};
type ClientReady = boolean;
type DidTimeout = boolean;

export const HOLIDAYS_OPTIMIZELY_CONFIG = {
  eventBatchSize: 100,
  eventFlushInterval: 3000,
  odpOptions: {
    disabled: true,
  },
  sdkKey: NEXT__OPTIMIZELY_SDK_KEY,
};

const optimizely = createInstance(HOLIDAYS_OPTIMIZELY_CONFIG);

export const OptimizelyWrapperProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isReady } = useRouter();
  const [userId, setUserId] = useState('default_user');

  useEffect(() => {
    try {
      setUserId(getUserUuid().toString());
    } catch (error) {
      logger.error(error);
    }
  }, [isReady]);

  if (!!optimizely) {
    return (
      <OptimizelyProvider
        optimizely={optimizely}
        user={{ id: userId, attributes: { user_id: userId } }}
      >
        {children}
      </OptimizelyProvider>
    );
  } else {
    logger.error('Optimizely error: cannot initialize optimizely');
    return <>{children}</>;
  }
};

/**
 * A React Hook wrapper for Optimizely useDecision
 * this is so we only need to import the Optimizely library
 * in this shared component (and not in the JQ and QH apps)
 *
 * Reference: https://docs.developers.optimizely.com/feature-experimentation/docs/usedecision-react
 */
export const useOptimizelyFeatureFlag = (
  featureKey: string,
  options?: DecideHooksOptions,
  overrides?: HookOverrides,
): [OptimizelyDecision, ClientReady, DidTimeout] => {
  const [decision, clientReady, didTimeout] = useDecision(
    featureKey,
    options,
    overrides,
  );

  // did decision fail with a critical error?
  if (clientReady && decision?.variationKey === null) {
    logger.warn('Optimizely error: ', decision['reasons']);
  }

  return [decision, clientReady, didTimeout];
};

export const customLogger = function (level: number, message: string): void {
  switch (level) {
    case LOG_LEVEL.INFO:
      logger.info(message);
      break;
    case LOG_LEVEL.DEBUG:
      logger.debug(message);
      break;
    case LOG_LEVEL.WARNING:
      logger.warn(message);
      break;
    case LOG_LEVEL.ERROR:
      logger.error(message);
      break;
    default:
      break;
  }
};
setLogger({
  log: customLogger,
});
// Can be 'info', 'debug', 'warn', 'error'
setLogLevel(OPTIMIZELY_LOG_LEVEL || 'warn');
